// @flow
import * as SentrySDK from "@sentry/react";

const Sentry = {
  init(): void {
    if (process.env.NODE_ENV === "production") {
      SentrySDK.init({
        dsn: "https://e5cfdc0ff7908eb02fba072569098563@o4505930445881344.ingest.sentry.io/4505930447847424",
        integrations: [new SentrySDK.BrowserTracing(), new SentrySDK.Replay()],
        tracesSampleRate: 0.2,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      });
    }
  },
  captureException(error: Error): void {
    if (process.env.NODE_ENV === "production") {
      SentrySDK.captureException(error);
    } else {
      console.error(error);
    }
  },
};

export default Sentry;
