// @flow
import * as React from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { Link, NavLink } from "react-router-dom";

const Navbar = {
  LoggedIn: function (): React.Node {
    const { user, logout } = useAuth0();

    const handleLogout = () => {
      logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    };

    return (
      <React.Fragment>
        <ul className="navbar-nav">
          <li className="nav-item">
            <NavLink to="/" className="nav-link">
              Головна
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/orders" className="nav-link">
              Замовлення
            </NavLink>
          </li>
        </ul>
        <ul className="navbar-nav ms-auto">
          <li className="nav-item dropdown">
            <button type="button" className="nav-link" data-bs-toggle="dropdown" aria-expanded="false">
              <img
                src={user.picture}
                width={40}
                height={40}
                className="rounded-circle"
                alt={user.name}
              />
            </button>

            <ul className="dropdown-menu">
              <li>
                <Link to="/profile" className="dropdown-item">
                  Профіль
                </Link>
              </li>
              <li>
                <Link to="/settings" className="dropdown-item">
                  Налаштування
                </Link>
              </li>
              <li>
                <button type="button" className="dropdown-item" onClick={handleLogout}>
                  Вийти
                </button>
              </li>
            </ul>
          </li>
        </ul>
      </React.Fragment>
    );
  },
  LoggedOut: function (): React.Node {
    return (
      <React.Fragment>
        <ul className="navbar-nav ms-auto">
          <li className="nav-item">
            <NavLink to="/login" className="nav-link">
              Вхід
            </NavLink>
          </li>
        </ul>
      </React.Fragment>
    );
  },
};

export default Navbar;
