// @flow
import * as React from "react";

function Footer(): React.Node {
  return (
    <footer className="bg-light py-4">
      <div className="container d-flex flex-wrap justify-content-between align-items-center">
        <div>
          <p className="mb-0">© 2023 Dryculator</p>
        </div>
        <div></div>
      </div>
    </footer>
  );
}

export default Footer;
