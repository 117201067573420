// @flow
import * as React from "react";

import { Auth0Provider } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

type Props = {
  children: React.Node,
};

function Auth0ProviderWithHistory({ children }: Props): React.Node {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

  const navigate = useNavigate();

  const handleRedirectCallback = (appState: { returnTo?: string }) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience,
        redirect_uri: window.location.origin,
      }}
      onRedirectCallback={handleRedirectCallback}>
      {children}
    </Auth0Provider>
  );
}

export default Auth0ProviderWithHistory;
