// @flow
import * as React from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { QueryClient, QueryClientProvider } from "react-query";

import Main from "./Main";
import Header from "./Header";
import Footer from "./Footer";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App(): React.Node {
  const { isLoading } = useAuth0();

  if (isLoading === true) {
    return null;
  }

  return (
    <React.Fragment>
      <Header />
      <QueryClientProvider client={queryClient}>
        <Main />
      </QueryClientProvider>
      <Footer />
    </React.Fragment>
  );
}

export default App;
