// @flow
import * as React from "react";

const DELAY = 1000;

function Loading(): React.Node {
  const [showMessage, setShowMessage] = React.useState<boolean>(false);

  React.useEffect(() => {
    const timerId = setTimeout(() => setShowMessage(true), DELAY);

    return () => {
      clearTimeout(timerId);
    };
  }, []);

  if (showMessage !== true) {
    return null;
  }

  return <p>Завантаження...</p>;
}

export default Loading;
