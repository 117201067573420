// @flow
import * as React from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";

type Props = {
  element: React.Node,
};

function PublicRoute({ element }: Props): React.Node {
  const { isAuthenticated } = useAuth0();

  if (isAuthenticated === true) {
    return <Navigate to="/" />;
  }

  return element;
}

export default PublicRoute;
