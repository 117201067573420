// @flow
import * as React from "react";

import Sentry from "./sentry";

import ApplicationError from "views/screens/ApplicationError";

Sentry.init();

type Props = {
  children: React.Node,
};

type State = {
  hasError: boolean,
};

class ErrorBoundary extends React.Component<Props, State> {
  state: State = {
    hasError: false,
  };

  static getDerivedStateFromError(): Partial<State> {
    return {
      hasError: true,
    };
  }

  render(): React.Node {
    if (this.state.hasError === true) {
      return <ApplicationError />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
