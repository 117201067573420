// @flow
import * as React from "react";

import { Route, Routes } from "react-router-dom";

import Loading from "views/components/Loading";
import PublicRoute from "views/components/PublicRoute";
import PrivateRoute from "views/components/PrivateRoute";

const Login = React.lazy(() => import("views/pages/Login"));
const Profile = React.lazy(() => import("views/pages/Profile"));
const Settings = React.lazy(() => import("views/pages/Settings"));
const Dashboard = React.lazy(() => import("views/pages/Dashboard"));

const OrderRoutes = React.lazy(() => import("views/pages/orders/routes"));

const NotFound = React.lazy(() => import("views/screens/NotFound"));

function Main(): React.Node {
  return (
    <main className="pt-4 pb-3">
      <React.Suspense
        fallback={
          <div className="container">
            <Loading />
          </div>
        }>
        <Routes>
          <Route path="/" element={<PrivateRoute element={<Dashboard />} />} />
          <Route path="/login" element={<PublicRoute element={<Login />} />} />
          <Route path="/profile" element={<PrivateRoute element={<Profile />} />} />
          <Route path="/settings" element={<PrivateRoute element={<Settings />} />} />
          <Route path="/orders/*" element={<PrivateRoute element={<OrderRoutes />} />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </React.Suspense>
    </main>
  );
}

export default Main;
